import React from 'react';
import { STATIC_FILES_URL } from '../urls';

export default function PlicarLogo(props: { path?: string; className?: string; style?: any }) {
  return (
    <a href="https://plicar.io" target="_blank" rel="noreferrer">
      <img
        alt="PlicAR Logo"
        src={`${STATIC_FILES_URL}/images/logo/PlicARLogo.svg`}
        className={props.className}
        style={props.style}
      />
    </a>
  );
}
