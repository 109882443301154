import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getModelMetadata, getPreviewModelMetadata } from '../lib/http-client';
import { ViewerDataType } from '../lib/types';
import ARGatewayiOS from '../component/argateway/ARGatewayiOS';
import ARGatewayAndroid from '../component/argateway/ARGatewayAndroid';
import ARGatewayPC from '../component/argateway/ARGatewayPC';
import amplitude from '../lib/amplitude';
import amplitudeEvents from '../lib/amplitudeEvents';
import ARGatewayUnpublished from '../component/argateway/ARGatewayUnpublished';
import ARGatewayExpired from '../component/argateway/ARGatewayExpired';

/** 1단계 환경(기기 및 브라우저) 판단 후 알맞은 컴포넌트 랜더링 */
/* 아이폰 */
/* 안드로이드 */
/* PC */

const ARGateway = () => {
  const param = useParams();
  const navigate = useNavigate();
  const viewerUid = param.viewerUid;

  const pageViewSent = useRef<boolean>(false);
  const modelViewSent = useRef<boolean>(false);

  const isNoAppleMobile = /Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  const isAppleMobile =
    /iPad|iPhone|iPod/.test(navigator.userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

  const [isPublished, setIsPublished] = useState<boolean>(true);
  const [isExpired, setIsExpired] = useState<boolean>(false);
  const [viewerData, setViewerdata] = useState<ViewerDataType>({
    modelGlbURL: null,
    modelUsdzURL: null,
    modelName: null,
    salesURL: null,
    ARViewerParams: {
      enableVerticalPlacement: null,
      resizable: null,
    },
    userTier: null,
  });

  const addProtocolInUrl = (link: string) => {
    if (link !== '' && link !== undefined && link !== null && !link.includes('https') && !link.includes('http')) {
      link = 'https://' + link;
    }
    return link;
  };

  useEffect(() => {
    // Amplitude: 페이지 접속 시 이벤트 전송
    const params = new URL(window.location.href).searchParams;

    if (pageViewSent.current === false) {
      const pageViewProps = {
        page_url_full: window.location.href,
        page_referrer_full: document.referrer,
        platform: isAppleMobile ? 'iOS' : isNoAppleMobile ? 'Android' : 'PC', // iOS, Android, PC
        access_type: params.get('access_type') ?? 'Direct_URL', // Direct_URL, QRCode, AR_Button_Link, etc...
      };
      amplitude.sendEvent(amplitudeEvents.argateway.zero_argateway, pageViewProps);
      amplitude.setPageView(pageViewProps);
      pageViewSent.current = true;
    }
  }, []);

  // 제품 메타데이터
  useEffect(() => {
    (async () => {
      try {
        const params = new URL(window.location.href).searchParams;
        const token = params.get('token');
        const salesUrl = params.get('salesUrl');

        let res;

        if (token) {
          res = await getPreviewModelMetadata(viewerUid ?? '', token);
        } else {
          res = await getModelMetadata(viewerUid ?? '');
        }

        if (res.result === true) {
          const fetchedViewerData = res.data.data;

          // ARGateway에 queryString 으로 전달된 salesUrl 이 있으면 모델에 포함된 url 이 아닌 salesUrl 로 표시해줍니다.
          if (salesUrl) {
            fetchedViewerData.salesURL = salesUrl;
          } else {
            fetchedViewerData.salesURL = fetchedViewerData.salesURL || 'https://plicar.io/';
          }

          fetchedViewerData.salesURL = addProtocolInUrl(fetchedViewerData.salesURL);
          fetchedViewerData.modelName = fetchedViewerData.modelName || 'PlicAR';
          fetchedViewerData.ARViewerParams = {
            enableVerticalPlacement: fetchedViewerData.enableVerticalPlacement,
            resizable: fetchedViewerData.resizable,
          };

          // Amplitude: 메타데이터 받은 후 이벤트 전송
          if (modelViewSent.current === false) {
            const modelViewProps = {
              viewer_uid: viewerUid,
              page_view: amplitude.pageView,
              product_name: fetchedViewerData.modelName,
            };
            amplitude.sendEvent(amplitudeEvents.argateway.zero_argateway_model_view, modelViewProps);
            amplitude.setModelView(modelViewProps);
            modelViewSent.current = true;
          }

          delete fetchedViewerData.enableVerticalPlacement;
          delete fetchedViewerData.resizable;

          setViewerdata((prevState) => {
            const nextState = {
              ...prevState,
              ...fetchedViewerData,
            };
            if (fetchedViewerData.ARViewerParams !== undefined) {
              nextState.ARViewerParams = {
                ...nextState.ARViewerParams,
                ...fetchedViewerData.ARViewerParams,
              };
            }
            return nextState;
          });
        } else {
          // max_viewcount 초과 시
          if (res.data.result === 'exceeded_max_view_count') {
            navigate('/error');
          }
          // 모델 공유 OFF인 상태
          if (res.data.result === 'model_not_published') {
            setIsPublished(false);
            if (modelViewSent.current === false) {
              const modelUnpublishProps = {
                viewer_uid: viewerUid,
                page_view: amplitude.pageView,
              };
              amplitude.sendEvent(amplitudeEvents.argateway.zero_argateway_unpublish, modelUnpublishProps);
              modelViewSent.current = true;
            }
          } else if (res.data.result === 'invliad_token' || res.data.result === 'invalid_token') {
            setIsExpired(true);
          }
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  // 모델 공유 OFF인 상태엔 알맞은 문구를 렌더링합니다. -> 관리자의 요청에 의해 AR,3D viewer 공유가 중지된 상태입니다.
  if (isPublished === false) {
    return <ARGatewayUnpublished />;
  }

  if (isExpired === true) {
    return <ARGatewayExpired />;
  }

  if ((viewerData.salesURL == null && viewerData.modelName == null) || viewerUid === undefined) {
    return <>loading...</>;
  }

  if (isNoAppleMobile === true) {
    // Android (scene viewer)
    return <ARGatewayAndroid viewerData={viewerData} />;
  } else if (isAppleMobile === true) {
    // iOS (ar quick look)
    return <ARGatewayiOS viewerData={viewerData} />;
  } else {
    // PC
    return <ARGatewayPC viewerUid={viewerUid} viewerData={viewerData} />;
  }
};

export default ARGateway;
