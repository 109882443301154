import * as amplitude from '@amplitude/analytics-browser';

export type AmplitudeEvent = {
  name: string;
  props?: object | any;
};

class AmplitudeLoader {
  private _userId: string;
  private _userInfo: object;
  private _startTimeInMilliseconds: number | null;
  private _pageView: object;
  private _modelView: object;

  constructor() {
    this._userId = '';
    this._userInfo = {};
    this._startTimeInMilliseconds = null;
    this._pageView = {};
    this._modelView = {};
  }

  get userId() {
    return this._userId;
  }

  get userInfo() {
    return this._userInfo;
  }

  get startTimeInMilliseconds() {
    return this._startTimeInMilliseconds;
  }

  get pageView() {
    return this._pageView;
  }

  get modelView() {
    return this._modelView;
  }

  initialize(amplitudeKey: string, userId?: string, options?: amplitude.Types.BrowserOptions) {
    amplitude.init(amplitudeKey, userId, options);
    if (userId) this._userId = userId;
  }

  setUserId(userId: string) {
    amplitude.setUserId(userId);
    this._userId = userId;
  }

  setUserInfo(key: string, info: string) {
    const userInfo = new amplitude.Identify();
    userInfo.set(key, info);
    amplitude.identify(userInfo);

    this._userInfo = { ...this.userInfo, key: info };
  }

  sendEvent(eventName: string, eventProps?: object) {
    if (eventProps) {
      amplitude.track(eventName, eventProps);
    } else {
      amplitude.track(eventName);
    }
  }

  async sendAsyncEvent(eventName: string, eventProps?: object) {
    if (eventProps) {
      const result = await amplitude.track(eventName, eventProps).promise;
      return result;
    } else {
      const result = await amplitude.track(eventName).promise;
      return result;
    }
  }

  setStartTime(timeInMilliseconds: number) {
    this._startTimeInMilliseconds = timeInMilliseconds;
  }

  setPageView(pageView: object) {
    this._pageView = pageView;
  }

  setModelView(modelView: object) {
    this._modelView = modelView;
  }

  getDuration(timeInMilliseconds: number) {
    if (this._startTimeInMilliseconds !== null) {
      return timeInMilliseconds - this._startTimeInMilliseconds;
    }
  }

  reset() {
    amplitude.reset();
  }
}

export default new AmplitudeLoader();

export const changeSignupTag = (tag: string) => {
  switch (tag) {
    case '모델 뷰어(3D)':
      return '3d_viewer';
    case '증강 현실(AR)':
      return 'ar';
    case '광고':
      return 'advertisement';
    case '가상 현실(VR)':
      return 'vr';
    case 'e-커머스':
      return 'ecommerce';
    case '웹페이지':
      return 'website';
    case '인쇄 홍보물':
      return 'printed_catalog';
    case '디지털 카탈로그':
      return 'digital_catalog';
    default:
      return tag;
  }
};
