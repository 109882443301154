import axios from 'axios';
import { ReturnValue } from './types';

export const isAxiosError = (value: any) => {
  return value.isAxiosError === true;
};

export const handleError = (error: any) => {
  console.log(error);
  let returnValue: ReturnValue = { result: false, data: {} };
  if (isAxiosError(error)) {
    if (error.response == null) {
      // network 에러
      return { result: false, data: {} };
    }
    returnValue = { result: false, status: error.response!.status, data: error.response!.data };
  }
  return returnValue;
};

export const getModelMetadata = async (viewer_uid: string) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_ARGATEWAY_API_ENDPOINT}/public/model/viewer?viewer_uid=${viewer_uid}`,
      {
        // withCredentials: true //  The value of the 'Access-Control-Allow-Origin' header in the response must not be the wildcard '*' when the request's credentials mode is 'include'. The credentials mode of requests initiated by the XMLHttpRequest is controlled by the withCredentials attribute.
        withCredentials: false,
      },
    );
    const data = res.data;
    return { result: true, data };
  } catch (error: any) {
    return handleError(error);
  }
};

export const getPreviewModelMetadata = async (viewer_uid: string, token: string) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_ARGATEWAY_API_ENDPOINT}/public/model/viewer/preview?viewer_uid=${viewer_uid}&token=${token}`,
      {
        // withCredentials: true //  The value of the 'Access-Control-Allow-Origin' header in the response must not be the wildcard '*' when the request's credentials mode is 'include'. The credentials mode of requests initiated by the XMLHttpRequest is controlled by the withCredentials attribute.
        withCredentials: false,
      },
    );
    const data = res.data;
    return { result: true, data };
  } catch (error: any) {
    return handleError(error);
  }
};
