import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './i18n/i18n';
import amplitude from './lib/amplitude';
import { nanoid } from 'nanoid';
import { Types } from '@amplitude/analytics-browser';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

if (process.env.REACT_APP_CTRL_SERVER_PROTOCOL === 'https') {
  // production 환경에서
  if (window.location.protocol === 'http:') {
    // http로 접속하는 사용자가 있으면
    const path = window.location.href.match(/#.*/) || '';
    window.location.href = `https://${window.location.host}/${path}`; // https 해당 경로로 연결시켜줍니다
  }
}

if (process.env.REACT_APP_ARGATEWAY_ANALYTICS_API_KEY) {
  const params = new URL(window.location.href).searchParams;
  const userid = params.get('userid');

  amplitude.initialize(process.env.REACT_APP_ARGATEWAY_ANALYTICS_API_KEY, undefined, {
    transport: Types.TransportType.SendBeacon,
  });
  amplitude.setUserId(userid ?? nanoid());
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
