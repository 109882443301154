/** @jsxImportSource @emotion/react */
import React from 'react';
import { PLICAR_COLORS, TYPOGRAPHY_STYLE } from '@reconlabs/reconlabs-fe-components';
import { useTranslation } from 'react-i18next';
import { STATIC_FILES_URL } from '../../urls';

const ARGatewayMaxViewCount = () => {
  const { t } = useTranslation();

  return (
    <div
      className="ErrorPage rdsfont"
      css={{
        position: 'relative',
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 48px',
        '@media (min-width: 800px)': {
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'flex-end',
        },
      }}
    >
      <div
        className="ErrorPage__info"
        css={{
          zIndex: 100,
          margin: 0,
          padding: 12,
          backgroundColor: 'rgba(255, 255, 255, 0.3)',
          borderRadius: 24,
          '@media (min-width: 800px)': {
            marginLeft: '12%',
            marginBottom: '24vh',
          },
        }}
      >
        <div
          className="ErrorPage__info__title"
          css={{
            color: PLICAR_COLORS.bi.primary[1],
            ...TYPOGRAPHY_STYLE.font_LG['24_extrabold'],
            marginBottom: 24,
            '@media (min-width: 800px)': {
              ...TYPOGRAPHY_STYLE.font_LG['40_extrabold'],
            },
          }}
        >
          {t('page.maxViewCount.title')}
        </div>
        <div
          className="ErrorPage__info__content"
          css={{
            color: PLICAR_COLORS.bi.primary[3],
            whiteSpace: 'pre-line',
            ...TYPOGRAPHY_STYLE.font_MD['16_bold'],

            '@media (min-width: 800px)': {
              ...TYPOGRAPHY_STYLE.font_MD['21_bold'],
            },
          }}
        >
          {t('page.maxViewCount.content')}
        </div>
      </div>
      <img
        src={`${STATIC_FILES_URL}/images/zero-max-viewcount.png`}
        className="ErrorPage__img"
        css={{
          position: 'absolute',
          zIndex: 0,
          right: 0,
          bottom: 0,
          maxWidth: '80%',

          '@media (min-width: 800px)': {
            maxWidth: '40%',
          },
        }}
      />
    </div>
  );
};

export default ARGatewayMaxViewCount;
