const argateway = {
  zero_argateway: 'zero_argateway',
  zero_argateway_model_view: 'zero_argateway_model_view',
  zero_argateway_unpublish: 'zero_argateway_unpublish',
};

const ar = {
  zero_argateway_ios_ar_view: 'zero_argateway_ios_ar_view',
  zero_argateway_ios_ar_experienced: 'zero_argateway_ios_ar_experienced',
  zero_argateway_android_ar_view: 'zero_argateway_android_ar_view',
  zero_argateway_android_ar_view_fallback: 'zero_argateway_android_ar_view_fallback',
  zero_argateway_android_ar_experienced_fallback: 'zero_argateway_android_ar_experienced_fallback',
};

export default { argateway, ar };
