/** @jsxImportSource @emotion/react */

import React from 'react';
import { keyframes } from '@emotion/react';

const LoadingSpinner = () => {
  const loadingI = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

  const bounce = keyframes` 
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
`;

  return (
    <div className="load-9">
      <div
        className="spinner"
        css={{
          animation: `${loadingI} 2s linear infinite`,
          position: 'relative',
          width: 100,
          height: 100,
          margin: '0 auto',
        }}
      >
        <div
          className="bubble-1"
          css={{
            animation: `${bounce} 2s ease-in-out infinite`,
            position: 'absolute',
            top: 0,
            width: 45,
            height: 45,
            borderRadius: '100%',
            backgroundColor: '#4b9cdb',
          }}
        ></div>
        <div
          className="bubble-2"
          css={{
            animation: `${bounce} 2s ease-in-out infinite`,
            animationDelay: '-1s',
            position: 'absolute',
            bottom: 0,
            width: 45,
            height: 45,
            borderRadius: '100%',
            backgroundColor: '#4b9cdb',
          }}
        ></div>
      </div>
    </div>
  );
};

export default LoadingSpinner;
