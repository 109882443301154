import React from 'react';
import { PLICAR_COLORS, RDSText } from '@reconlabs/reconlabs-fe-components';
import { useTranslation } from 'react-i18next';

const ARGatewayUnpublished = () => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 45px',
      }}
    >
      <RDSText
        type="LG/24_bold"
        style={{ whiteSpace: 'pre-wrap', textAlign: 'center', color: PLICAR_COLORS.text.secondary, maxWidth: 330 }}
      >
        {t('page.unpublish')}
      </RDSText>
    </div>
  );
};

export default ARGatewayUnpublished;
