import React, { useEffect, useState } from 'react';
import {
  ARGatewayiOSFacebook,
  ARGatewayiOSInstagram,
  ARGatewayiOSKakaotalk,
  ARGatewayiOSNaverBlog,
  ARGatewayiOSLine,
  ARGatewayiOSUnsupport,
} from './argateway.excepts/ARGatewayiOSExcepts';
import { ViewerDataType } from '../../lib/types';
import { useTranslation } from 'react-i18next';
import { RDSText } from '@reconlabs/reconlabs-fe-components';
import Cube from '../Cube';
import amplitude from '../../lib/amplitude';
import amplitudeEvents from '../../lib/amplitudeEvents';
import LoadingSpinner from './LoadingSpinner';

// Code for checking AR use period
// TODO: 이후 WebXR을 사용하게 되면 모델이 바뀔때마다 변수를 재사용할 필요가 있음
let ARExperienced = false;
let ARStartedTime: Date;

function checkIOSSupportedBrowser() {
  // AR Support Detection: https://webkit.org/blog/8421/viewing-augmented-reality-assets-in-safari-for-ios/
  const anchorForCheck = document.createElement('a');
  if (anchorForCheck.relList.supports('ar')) {
    return 'supportBrowser';
  }

  const agt = navigator.userAgent.toLowerCase();

  // 웨일, chrome, naver 앱 : support 확인 완료, 하지만 relList에서는 false로 나옴. // iphone chrome에서의 useragent는 criOS를 포함
  if (/naver|whale|crios/.test(agt) && /naverworks/.test(agt) === false) {
    return 'supportBrowser';
  }

  // 카카오톡
  if (agt.indexOf('kakaotalk') !== -1) {
    return 'kakaotalk';
  }

  // 라인 & 라인웍스
  if (agt.indexOf('line') !== -1 || agt.indexOf('naverworks') !== -1) {
    return 'line';
  }

  // 인스타그램 웹뷰
  if (agt.indexOf('instagram') !== -1) {
    return 'instagram';
  }

  // 페이스북
  if (agt.indexOf('fban') !== -1 || agt.indexOf('fbav') !== -1) {
    return 'facebook';
  }

  // 네이버블로그 브라우저
  if (agt.indexOf('blog') !== -1) {
    return 'blog';
  }

  // Google Search App
  if (agt.indexOf('gsa') > -1) {
    return 'unsupportBrowser';
  }

  return 'unsupportBrowser';
}

type ARGatewayiOSProps = {
  viewerData: ViewerDataType;
};

export default function ARGatewayiOS({ viewerData }: ARGatewayiOSProps) {
  const { t } = useTranslation();
  const translate = t;

  const [isAppleSupportedBrowser, setIsAppleSupportedBrowser] = useState('');
  const [usdzSrc, setUsdzSrc] = useState<string | null>(null);
  const [isUsdzSrcReady, setIsUsdzSrcReady] = useState(false);
  const [isAtagReady, setIsAtagReady] = useState(false);
  // AR이 시작되었다가 끝나고, 다시 원래 브라우저 화면으로 돌아오면 true로 바뀝니다.
  const [arDone, setArDone] = useState(false);

  // IOS에서 AR뷰어가 종료되고 난 후, 다시 사파리로 돌아올 때 sendLogDataAndCloseTab 함수를 실행합니다.
  const eventAfterARViwerClosed = () => {
    async function sendLogDataAndCloseTab() {
      if (ARExperienced === false && document.visibilityState === 'visible') {
        ARExperienced = true;

        if (ARStartedTime != undefined) {
          const AREndedTime = new Date();

          // @noel Amplitude: 이용 시간 Amplitude로 전송
          const ARUseTime = AREndedTime.getTime() - ARStartedTime.getTime(); // millisec
          await amplitude.sendAsyncEvent(amplitudeEvents.ar.zero_argateway_ios_ar_experienced, {
            ar_period: ARUseTime,
            model_view: amplitude.modelView,
          });

          // AR 경험 후에 정상적으로 돌아왔을 때에만 해당 state를 업데이트 합니다. 이는 closeWindow가 불가능한 경우, 안내 화면을 렌더링 합니다.
          setArDone(true);

          setTimeout(function closeWindow() {
            window.close();
            setTimeout(closeWindow, 100);
          }, 100);
        }
      }
    }
    // iOS Chrome의 경우에는 visibilitychange 이벤트에 함수를 호출한다.
    if (/naver|crios/.test(navigator.userAgent.toLowerCase())) {
      document.addEventListener('visibilitychange', sendLogDataAndCloseTab);
    } else {
      window.addEventListener('focus', sendLogDataAndCloseTab, { once: true });
    }
  };

  // 0. 브라우저 정보 받아오기
  useEffect(() => {
    setIsAppleSupportedBrowser(checkIOSSupportedBrowser());
  }, []);

  // 1. 지원하는 애플 브라우져일 경우 모델경로(usdzSrc) 받아오기
  useEffect(() => {
    if (isAppleSupportedBrowser === 'supportBrowser') {
      (async () => {
        try {
          if (viewerData.modelUsdzURL !== null) {
            setUsdzSrc(viewerData.modelUsdzURL);
            setIsUsdzSrcReady(true);
          }
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, [isAppleSupportedBrowser]);

  // GA4 세팅하기

  // 2. Quick Look <a/> 태그 생성
  useEffect(() => {
    if (isUsdzSrcReady) {
      let parameter = '';
      // ARViewerParams = {resizable: boolean} 이렇게 하나만 있던가 {resizable: booelan, enable_vertical_placement: booelan} 이렇게 두개, or '' or undefined
      if (viewerData.ARViewerParams?.resizable === false) {
        parameter = `#allowsContentScaling=0`;
      }
      const anchor = document.createElement('a'); // IOS에서 anchor를 전역변수로 사용하려고 하면 원인을 알 수 없는 오동작이 일어남
      anchor.setAttribute('rel', 'ar');
      anchor.appendChild(document.createElement('img')); // IOS에서 중간모델확인단계 없이 바로 카메라 AR모드로 이동하기 위한 코드
      anchor.setAttribute('id', 'Plicar-Apple-AR');
      anchor.setAttribute('href', usdzSrc + parameter);
      document.body.appendChild(anchor);
      setIsAtagReady(true);
    }
  }, [isUsdzSrcReady]);

  // 3. Quick Look <a/> 클릭
  useEffect(() => {
    if (isUsdzSrcReady && isAtagReady) {
      ARStartedTime = new Date();
      eventAfterARViwerClosed();
      const appleAR: HTMLAnchorElement | null = document.querySelector('#Plicar-Apple-AR');
      if (appleAR) {
        // Amplitude: 시작 시 데이터 전송
        amplitude.sendEvent(amplitudeEvents.ar.zero_argateway_ios_ar_view, amplitude.modelView);
        appleAR.click();
      }
    }
  }, [isUsdzSrcReady, isAtagReady]);

  // 기타. 미지원브라우저 안내 페이지 렌더링
  if (isAppleSupportedBrowser === 'kakaotalk') {
    return <ARGatewayiOSKakaotalk translate={translate} />;
  }
  if (isAppleSupportedBrowser === 'instagram') {
    return <ARGatewayiOSInstagram translate={translate} />;
  }
  if (isAppleSupportedBrowser === 'facebook') {
    return <ARGatewayiOSFacebook translate={translate} />;
  }
  if (isAppleSupportedBrowser === 'blog') {
    return <ARGatewayiOSNaverBlog translate={translate} />;
  }
  if (isAppleSupportedBrowser === 'line') {
    return <ARGatewayiOSLine translate={translate} />;
  }
  if (isAppleSupportedBrowser === 'unsupportBrowser') {
    return <ARGatewayiOSUnsupport translate={translate} />;
  }

  // 4. 정상 브라우저일 경우 렌더링 되는 부분
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '100vh',
        paddingTop: 40,
        gap: 80,
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          flexGrow: 1,
          gap: 40,
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {arDone ? <LoadingSpinner /> : <Cube />}
          <div style={{ height: 250, width: 0 }} />
        </div>
        <div style={{ textAlign: 'left', padding: '0 36px' }}>
          {arDone ? (
            <RDSText type="MD/16_extrabold">{translate('page.argateway.arExperienced')}</RDSText>
          ) : (
            <>
              <RDSText type="MD/16_extrabold">{translate('page.argateway.mobile.title')}</RDSText>
              <RDSText type="SM/14_semibold" style={{ color: '#646466' }}>
                {translate('page.argateway.mobile.iphone')}
              </RDSText>
              <RDSText type="SM/14_semibold" style={{ color: '#646466' }}>
                {translate('page.argateway.mobile.android')}
              </RDSText>
            </>
          )}
        </div>
      </div>
      <RDSText type="SM/14_semibold" style={{ color: '#BBBBBF', paddingBottom: 16 }}>
        Powered by PlicAR
      </RDSText>
    </div>
  );
}
