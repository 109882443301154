/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useCallback } from 'react';
import QRCode from 'qrcode.react';
import PlicarLogo from '../PlicarLogo';
import { STATIC_FILES_URL } from '../../urls';
import {
  BREAKPOINT,
  ChevronUpIcon,
  CSSColor,
  InfoOutlinedIcon,
  PLICAR_COLORS,
  RDSDropdown,
  RDSOption,
  RDSPlicarZeroViewer,
  RDSText,
  TYPOGRAPHY_STYLE,
  XMarkIcon,
} from '@reconlabs/reconlabs-fe-components';
import { ViewerDataType } from '../../lib/types';
import { useTranslation } from 'react-i18next';
import amplitude from '../../lib/amplitude';

export default function ARGatewayPC({ viewerUid, viewerData }: { viewerUid: string; viewerData: ViewerDataType }) {
  const { t, i18n } = useTranslation();
  const translate = t;
  const locale = i18n.resolvedLanguage;

  const params = new URL(window.location.href).searchParams;
  const token = params.get('token');

  const [isAtTopScroll, setIsAtTopScroll] = useState(true);
  const [isAtBottomScroll, setIsAtBottomScroll] = useState(false);
  const [lightingOption, setLightingOption] = useState<'default' | 'studio' | 'neutral' | 'outdoor' | 'indoor'>(
    'default',
  );
  const [measurementOption, setMeasurementOption] = useState<'none' | 'mm' | 'cm' | 'inch'>('none');
  const [controllModalOpen, setControllModalOpen] = useState(false);

  const changeScrollButtonDirection = useCallback(() => {
    const rootElem = document.getElementById('root');
    if (rootElem !== null) {
      rootElem.addEventListener('scroll', () => {
        if (rootElem.scrollTop < 50) {
          setIsAtTopScroll(true);
        } else {
          setIsAtTopScroll(false);
        }
      });
    }
  }, []);

  const changeHeaderBackground = useCallback(() => {
    const rootElem = document.getElementById('root');

    if (rootElem !== null) {
      if (rootElem.scrollTop + 20 >= rootElem.scrollHeight - rootElem.clientHeight) {
        setIsAtBottomScroll(true);
      } else {
        setIsAtBottomScroll(false);
      }
    }
  }, []);

  useEffect(() => {
    const rootElem = document.getElementById('root');

    if (rootElem !== null) {
      rootElem.addEventListener('scroll', () => {
        changeScrollButtonDirection();
        changeHeaderBackground();
      });
      return () => {
        rootElem.removeEventListener('scroll', () => {
          changeScrollButtonDirection();
          changeHeaderBackground();
        });
      };
    }
  }, []);

  const handleScrollButton = () => {
    const rootElem = document.getElementById('root');
    if (rootElem !== null) {
      if (isAtBottomScroll) {
        rootElem.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      } else {
        rootElem.scrollBy({
          top: window.innerHeight * 0.7,
          left: 0,
          behavior: 'smooth',
        });
      }
    }
  };

  return (
    <>
      <div
        style={{
          backgroundColor: PLICAR_COLORS.bg.lightGrey,
        }}
      >
        {/* Top Bar */}
        <div
          css={{
            height: '60px',
            width: '100%',
            padding: '0 24px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            position: 'sticky',
            top: 0,
            left: 0,
            zIndex: 10,
            backgroundColor: 'white',
            borderBottom: isAtTopScroll ? 'none' : '1px solid #E0E0E0',
            transition: 'all 100ms linear',
            [BREAKPOINT.tablet]: {
              padding: '0 100px',
            },
          }}
        >
          <PlicarLogo
            path={locale === 'ko' ? 'https://plicar.io' : 'https://plicar.io/en/'}
            style={{ width: 66, height: 28 }}
          />
          <h1 style={{ fontWeight: 700, fontSize: 18, lineHeight: '116.7%' }}>
            {/* AR 커머스의 시작, 플리카 */}
            {translate('page.argateway.topbar.title')}
          </h1>
        </div>
        {/* Main Content - QR코드 */}
        <div
          className="Background-QRCode-Contain-Div"
          css={{
            padding: '132px 40px 82px',
            backgroundColor: '#fff',
            [BREAKPOINT.tablet]: {
              padding: '132px 100px 82px',
            },
          }}
        >
          <RDSText classname="qr-title" type="LG/40_extrabold" align="center" style={{ marginBottom: 65 }}>
            {locale === 'ko' ? (
              <>
                내 공간을 담은{' '}
                <span
                  css={{
                    background: PLICAR_COLORS.bi.gradation[1],
                    backgroundClip: 'text',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                  }}
                >
                  AR 쇼핑
                </span>
              </>
            ) : (
              <>
                <span
                  css={{
                    background: PLICAR_COLORS.bi.gradation[1],
                    backgroundClip: 'text',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                  }}
                >
                  AR Shopping
                </span>{' '}
                Brought to Your Home
              </>
            )}
          </RDSText>
          <div
            className="qr-contents"
            css={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div
              css={{
                display: 'inline-block',
                marginBottom: 12,
                border: '3px solid transparent',
                borderRadius: 8.67,
                backgroundImage: `linear-gradient(#fff, #fff), ${PLICAR_COLORS.bi.gradation[1]}`,
                backgroundOrigin: 'border-box',
                backgroundClip: 'content-box, border-box',
              }}
            >
              {/* @ts-ignore */}
              <QRCode
                size={500}
                style={{
                  boxSizing: 'content-box',
                  width: '200px',
                  height: '200px',
                  padding: 30,
                }}
                className="PUBLISH-QR-CODE"
                level="M"
                value={`${process.env.REACT_APP_ARGATEWAY_HOST}/${viewerUid}?access_type=QRCode&userid=${
                  amplitude.userId
                }${token ? `&token=${token}` : ''}`} // 도메인 대소문자 구분 없음. query 대소문자 구분 존재
              />
            </div>
            <RDSText type="LG/24_extrabold" style={{ marginBottom: 107 }}>
              {locale === 'ko' ? 'QR 코드를 촬영해주세요' : 'Please scan the QR code'}
            </RDSText>
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 30,
                [BREAKPOINT.tablet]: {
                  flexDirection: 'row',
                  gap: 70,
                  alignItems: 'flex-start',
                },
              }}
            >
              <div>
                <img
                  src="/images/arviewer-example.png"
                  width={297}
                  height={147}
                  alt="스마트폰으로 AR Viewer를 사용하는 예시를 보여주는 이미지입니다."
                />
                <RDSText type="SM/12_semibold" style={{ color: PLICAR_COLORS.grey[400], marginTop: 8 }} align="center">
                  {locale === 'ko' ? 'AR 예시 화면' : 'AR view example'}
                </RDSText>
              </div>
              <div css={{ marginTop: 8 }}>
                <RDSText
                  type="MD/16_bold"
                  style={{
                    whiteSpace: 'pre-line',
                    marginBottom: 14,
                  }}
                >
                  {/* 스마트폰 기본 카메라 앱에서<br/>
              아래의 QR을 비춘 후 뜨는 링크를 눌러주세요 */}
                  {translate('page.argateway.body')}
                </RDSText>
                <div
                  className="possible-device-info"
                  style={{
                    fontSize: 12,
                    lineHeight: '19px',
                    color: 'rgba(0, 0, 0, 0.6)',
                    whiteSpace: 'pre-line',
                  }}
                >
                  {/* *AR기능은 현재 아래의 기기에서만 정상 작동 합니다: <br/><br/>
            iPhone: iOS 12이상 <br/>
          Android: 8.0+ 이상 ARCore 1.9 지원기기 <br/> */}
                  {locale === 'ko' ? (
                    <>
                      <div>*AR기능은 현재 아래의 기기에서만 정상 작동 합니다.</div>
                      <div>
                        iPhone: <b>iOS 12이상</b>
                      </div>
                      <div>
                        Android: <b>8.0+ 이상</b> (ARCore 1.9 지원기기)
                      </div>
                    </>
                  ) : (
                    translate('page.argateway.deviceInfo')
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Sub Content - Plicar-viewer */}
        <div
          className="Background-Plicar-Viewer-Contain-Div"
          css={{
            padding: '125px 40px 117px',
            [BREAKPOINT.tablet]: {
              padding: '125px 100px 117px',
            },
          }}
        >
          <RDSText type="LG/40_extrabold" align="center" style={{ marginBottom: 60 }}>
            {locale === 'ko' ? (
              <>
                플리카{' '}
                <span
                  style={{
                    background: PLICAR_COLORS.bi.gradation[1],
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                  }}
                >
                  3D
                </span>{' '}
                Viewer
              </>
            ) : (
              <>
                PlicAR{' '}
                <span
                  style={{
                    background: PLICAR_COLORS.bi.gradation[1],
                    backgroundClip: 'text',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                  }}
                >
                  3D
                </span>{' '}
                Viewer
              </>
            )}
          </RDSText>
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              gap: 54,
              [BREAKPOINT.pc]: {
                flexDirection: 'row',
              },
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <div css={{ minWidth: 250 }}>
                <RDSText type="MD/18_extrabold" style={{ color: '#646464', marginBottom: 20 }}>
                  {/* 3D 환경을 설정해보세요! */}
                  {translate('page.argateway.viewer.controls')}
                </RDSText>
                <RDSDropdown
                  onChange={setLightingOption}
                  value={lightingOption}
                  customStyles={{ marginBottom: 10 }}
                  width="100%"
                >
                  <RDSOption value="default">{locale === 'ko' ? '기본 조명' : 'Default Lights'}</RDSOption>
                  <RDSOption value="studio">{locale === 'ko' ? '스튜디오 조명' : 'Studio Lights'}</RDSOption>
                  <RDSOption value="neutral">{locale === 'ko' ? '무색조명' : 'Neutral Lights'}</RDSOption>
                  <RDSOption value="indoor">{locale === 'ko' ? '실내조명' : 'Indoor Lights'}</RDSOption>
                  <RDSOption value="outdoor">{locale === 'ko' ? '실외조명' : 'Outdoor Lights'}</RDSOption>
                </RDSDropdown>
                <RDSDropdown onChange={setMeasurementOption} value={measurementOption} width="100%">
                  <RDSOption value="none">{locale === 'ko' ? '치수 숨김' : 'Hide Measurements'}</RDSOption>
                  <RDSOption value="mm">{locale === 'ko' ? '밀리미터 (mm)' : 'Millimeter (mm)'}</RDSOption>
                  <RDSOption value="cm">{locale === 'ko' ? '센치미터 (cm)' : 'Centimeter (cm)'}</RDSOption>
                  <RDSOption value="inch">{locale === 'ko' ? '인치 (inch)' : 'Inch'}</RDSOption>
                </RDSDropdown>
              </div>
              <button
                onClick={() => setControllModalOpen(true)}
                css={{
                  color: PLICAR_COLORS.text.secondary,
                  display: 'flex',
                  gap: 10,
                  alignItems: 'center',
                  width: 'fit-content',
                  marginTop: 12,
                  alignSelf: 'flex-end',
                  ...TYPOGRAPHY_STYLE.font_SM['14_medium'],
                  [BREAKPOINT.pc]: {
                    marginTop: 0,
                    alignSelf: 'flex-start',
                  },
                }}
              >
                <InfoOutlinedIcon color={PLICAR_COLORS.grey[500] as CSSColor} />
                {/* 3D viewer 확인 방법 */}
                {translate('page.argateway.viewer.info')}
              </button>
            </div>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {
                <RDSPlicarZeroViewer
                  // viewerUid={viewerUid}
                  viewerData={JSON.stringify(viewerData)}
                  modelViewerScriptSource={`${STATIC_FILES_URL}/script/plicar-viewer.min.js`}
                  lightingMode={lightingOption}
                  measureMode={measurementOption}
                  // @noel Amplitude: <plicarzero-viewer> 에 userId 추가 -> 뷰어 컴포넌트에서 이를 이용하여 데이터로깅 처리
                  modelViewerAttributes={{ 'analytics-user-id': amplitude.userId }}
                />
              }
            </div>
          </div>
        </div>
        {/* Bottom */}
        <div
          style={{
            height: 70,
            borderTop: '1px solid #E0E0E0',
            width: '100%',
            textAlign: 'center',
            fontSize: '1rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            css={{
              fontWeight: 350,
              fontSize: 12,
              lineHeight: '23px',
              color: PLICAR_COLORS.text.secondary,
            }}
          >
            Copyright 2022. RECON Labs Inc. All rights reserved.
          </div>
        </div>
        {/* Scroll Button */}
        <button
          css={{
            position: 'fixed',
            right: 32,
            bottom: 106,
            zIndex: 3,
            backgroundColor: PLICAR_COLORS.bi.primary[4],
            padding: 8,
            borderRadius: 12,
            [BREAKPOINT.pc]: {
              display: 'none',
            },
          }}
          onClick={handleScrollButton}
        >
          <ChevronUpIcon
            color={PLICAR_COLORS.bi.primary[1]}
            css={{
              transform: isAtBottomScroll ? '' : 'rotate(180deg)',
              transition: 'transform 150ms linear',
            }}
          />
        </button>
      </div>
      {/* 사용법안내 모달창 */}
      {controllModalOpen && (
        <div
          style={{
            width: '100vw',
            height: '100vh',
            background: 'rgba(0, 0, 0, 0.7)',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 100,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={() => setControllModalOpen(false)}
        >
          <XMarkIcon
            style={{
              position: 'fixed',
              right: '3rem',
              top: '3rem',
              width: '3rem',
              height: '3rem',
              color: 'white',
              cursor: 'pointer',
            }}
            onClick={() => setControllModalOpen(false)}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '2rem',
              height: 'min-content',
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', gap: '0.7rem' }}>
              <img style={{ height: '80px' }} src={`${STATIC_FILES_URL}/images/argateway/dark_rotate.svg`} />
              <div>
                <span
                  style={{
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    color: 'white',
                  }}
                >
                  Rotate
                </span>
                <br />
                <span style={{ color: 'white' }}>
                  {/* 좌클릭을 누른 상태에서 */}
                  {translate('page.demo.modal.rotate.description1')}
                  <br />
                  {/* 원하는 방향으로 회전 */}
                  {translate('page.demo.modal.rotate.description2')}
                </span>
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '0.7rem' }}>
              <img style={{ height: '67px' }} src={`${STATIC_FILES_URL}/images/argateway/dark_zoom.svg`} />
              <div>
                <span
                  style={{
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    color: 'white',
                  }}
                >
                  Zoom
                </span>
                <br />
                <span style={{ color: 'white' }}>
                  {/* 마우스 휠을 조작하여  */}
                  {translate('page.demo.modal.zoom.description1')}
                  <br />
                  {/* 확대 축소 */}
                  {translate('page.demo.modal.zoom.description2')}
                </span>
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '0.7rem' }}>
              <img style={{ height: '80px' }} src={`${STATIC_FILES_URL}/images/argateway/dark_move.svg`} />
              <div>
                <span
                  style={{
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    color: 'white',
                  }}
                >
                  Move
                </span>
                <br />
                <span style={{ color: 'white' }}>
                  {/* 우클릭을 누른 상태에서 */}
                  {translate('page.demo.modal.move.description1')}
                  <br />
                  {/* 원하는 방향으로 이동 */}
                  {translate('page.demo.modal.move.description2')}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* 사용법안내 모달창 END*/}
    </>
  );
}
