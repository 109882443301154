import i18next from "i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import englishMessages from "./en.json";
import koreanMessages from "./ko.json";

i18next
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      ko: {
        translation: koreanMessages,
      },
      en: {
        translation: englishMessages,
      },
    },
    fallbackLng: "ko",
    debug: true,
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    // Language Detector Option https://github.com/i18next/i18next-browser-languageDetector
    detection: {
      // 머저 querystring ?lng=LANGUAGE를 검사하고,  브라우저 언어, localStorage 순으로 검사합니다.
      order: ["querystring", "navigator", "localStorage"],
      lookupLocalStorage: "zeroargw-i18next",
      lookupSessionStorage: "zeroargw-i18next",
      caches: ["localStorage"],
    },
  });

export default i18next;
