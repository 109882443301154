import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ARGateway from './pages/ARGateway';
import ARGatewayFallback from './pages/ARGatewayFallback';
import ARGatewayMaxViewCount from './component/argateway/ARGatewayMaxViewCount';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/:viewerUid" element={<ARGateway />} />
        <Route path="/:viewerUid/fallback" element={<ARGatewayFallback />} />
        <Route path="/error" element={<ARGatewayMaxViewCount />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
