import React from "react";
import "./CubeLoading.css";

const Cube = () => {
  return (
    <div className="cube__container">
      <div className="cube__h1Container">
        <div className="cube__cube cube__h1 cube__w1 cube__l1">
          <div className="cube__face cube__top"></div>
          <div className="cube__face cube__left"></div>
          <div className="cube__face cube__right"></div>
        </div>

        <div className="cube__cube cube__h1 cube__w1 cube__l2">
          <div className="cube__face cube__top"></div>
          <div className="cube__face cube__left"></div>
          <div className="cube__face cube__right"></div>
        </div>

        <div className="cube__cube cube__h1 cube__w1 cube__l3">
          <div className="cube__face cube__top"></div>
          <div className="cube__face cube__left"></div>
          <div className="cube__face cube__right"></div>
        </div>

        <div className="cube__cube cube__h1 cube__w2 cube__l1">
          <div className="cube__face cube__top"></div>
          <div className="cube__face cube__left"></div>
          <div className="cube__face cube__right"></div>
        </div>

        <div className="cube__cube cube__h1 cube__w2 cube__l2">
          <div className="cube__face cube__top"></div>
          <div className="cube__face cube__left"></div>
          <div className="cube__face cube__right"></div>
        </div>

        <div className="cube__cube cube__h1 cube__w2 cube__l3">
          <div className="cube__face cube__top"></div>
          <div className="cube__face cube__left"></div>
          <div className="cube__face cube__right"></div>
        </div>

        <div className="cube__cube cube__h1 cube__w3 cube__l1">
          <div className="cube__face cube__top"></div>
          <div className="cube__face cube__left"></div>
          <div className="cube__face cube__right"></div>
        </div>

        <div className="cube__cube cube__h1 cube__w3 cube__l2">
          <div className="cube__face cube__top"></div>
          <div className="cube__face cube__left"></div>
          <div className="cube__face cube__right"></div>
        </div>

        <div className="cube__cube cube__h1 cube__w3 cube__l3">
          <div className="cube__face cube__top"></div>
          <div className="cube__face cube__left"></div>
          <div className="cube__face cube__right"></div>
        </div>
      </div>

      <div className="cube__h2Container">
        <div className="cube__cube cube__h2 cube__w1 cube__l1">
          <div className="cube__face cube__top"></div>
          <div className="cube__face cube__left"></div>
          <div className="cube__face cube__right"></div>
        </div>

        <div className="cube__cube cube__h2 cube__w1 cube__l2">
          <div className="cube__face cube__top"></div>
          <div className="cube__face cube__left"></div>
          <div className="cube__face cube__right"></div>
        </div>

        <div className="cube__cube cube__h2 cube__w1 cube__l3">
          <div className="cube__face cube__top"></div>
          <div className="cube__face cube__left"></div>
          <div className="cube__face cube__right"></div>
        </div>

        <div className="cube__cube cube__h2 cube__w2 cube__l1">
          <div className="cube__face cube__top"></div>
          <div className="cube__face cube__left"></div>
          <div className="cube__face cube__right"></div>
        </div>

        <div className="cube__cube cube__h2 cube__w2 cube__l2">
          <div className="cube__face cube__top"></div>
          <div className="cube__face cube__left"></div>
          <div className="cube__face cube__right"></div>
        </div>

        <div className="cube__cube cube__h2 cube__w2 cube__l3">
          <div className="cube__face cube__top"></div>
          <div className="cube__face cube__left"></div>
          <div className="cube__face cube__right"></div>
        </div>

        <div className="cube__cube cube__h2 cube__w3 cube__l1">
          <div className="cube__face cube__top"></div>
          <div className="cube__face cube__left"></div>
          <div className="cube__face cube__right"></div>
        </div>

        <div className="cube__cube cube__h2 cube__w3 cube__l2">
          <div className="cube__face cube__top"></div>
          <div className="cube__face cube__left"></div>
          <div className="cube__face cube__right"></div>
        </div>

        <div className="cube__cube cube__h2 cube__w3 cube__l3">
          <div className="cube__face cube__top"></div>
          <div className="cube__face cube__left"></div>
          <div className="cube__face cube__right"></div>
        </div>
      </div>

      <div className="cube__h3Container">
        <div className="cube__cube cube__h3 cube__w1 cube__l1">
          <div className="cube__face cube__top"></div>
          <div className="cube__face cube__left"></div>
          <div className="cube__face cube__right"></div>
        </div>

        <div className="cube__cube cube__h3 cube__w1 cube__l2">
          <div className="cube__face cube__top"></div>
          <div className="cube__face cube__left"></div>
          <div className="cube__face cube__right"></div>
        </div>

        <div className="cube__cube cube__h3 cube__w1 cube__l3">
          <div className="cube__face cube__top"></div>
          <div className="cube__face cube__left"></div>
          <div className="cube__face cube__right"></div>
        </div>

        <div className="cube__cube cube__h3 cube__w2 cube__l1">
          <div className="cube__face cube__top"></div>
          <div className="cube__face cube__left"></div>
          <div className="cube__face cube__right"></div>
        </div>

        <div className="cube__cube cube__h3 cube__w2 cube__l2">
          <div className="cube__face cube__top"></div>
          <div className="cube__face cube__left"></div>
          <div className="cube__face cube__right"></div>
        </div>

        <div className="cube__cube cube__h3 cube__w2 cube__l3">
          <div className="cube__face cube__top"></div>
          <div className="cube__face cube__left"></div>
          <div className="cube__face cube__right"></div>
        </div>

        <div className="cube__cube cube__h3 cube__w3 cube__l1">
          <div className="cube__face cube__top"></div>
          <div className="cube__face cube__left"></div>
          <div className="cube__face cube__right"></div>
        </div>

        <div className="cube__cube cube__h3 cube__w3 cube__l2">
          <div className="cube__face cube__top"></div>
          <div className="cube__face cube__left"></div>
          <div className="cube__face cube__right"></div>
        </div>

        <div className="cube__cube cube__h3 cube__w3 cube__l3">
          <div className="cube__face cube__top"></div>
          <div className="cube__face cube__left"></div>
          <div className="cube__face cube__right"></div>
        </div>
      </div>
    </div>
  );
};

export default Cube;
