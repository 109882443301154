import React, { useEffect, useState } from 'react';
import styles from './ARGatewayiOSExcepts.module.css';
import { STATIC_FILES_URL } from '../../../urls';
import { RDSButton, RDSText } from '@reconlabs/reconlabs-fe-components';

function ARGatewayHeader(props: { title_1: string; title_2: string }) {
  return (
    <div className={styles['header']}>
      <RDSText type="MD/16_extrabold" style={{ color: '#646466' }}>
        {props.title_1}
      </RDSText>
      <img
        className={styles['QRlogo-img']}
        src={`${STATIC_FILES_URL}/images/argateway/ARGatewayError.png`}
        alt="aseeo-logo"
      ></img>
      <RDSText type="MD/16_extrabold" classname={styles['error-title2']}>
        {props.title_2}
      </RDSText>
    </div>
  );
}

export function ARGatewayiOSChrome({ translate }: { translate: Function }) {
  const [vh, setVh] = useState<number>(0);

  function copyToClipboard(text: string) {
    const inputc = document.body.appendChild(document.createElement('input'));
    inputc.value = window.location.href;
    inputc.readOnly = true;
    inputc.focus();
    inputc.select();
    try {
      document.execCommand('copy'); // deprecated but still works
    } catch {
      inputc.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(inputc.value); // New API But dont support
    }
    const parentNode = inputc.parentNode;
    if (parentNode) parentNode.removeChild(inputc);
    // 주소가 복사되었습니다.\nSafari에서 주소를 붙여넣으세요.
    alert(translate('alert.argateway.ios.copyLink'));
  }

  useEffect(() => {
    setVh(window.innerHeight);
  }, []);

  return (
    <div className={styles['page']} style={{ minHeight: vh + 'px' }}>
      <div className={styles['headerAndContents']}>
        {/* <ARGatewayHeader title_1="아직 지원하지 않는 브라우저입니다." title_2="Safari 로 AR을 체험하세요."/> */}
        <ARGatewayHeader
          title_1={translate('page.argateway.ios.notSupport.title1')}
          title_2={translate('page.argateway.ios.notSupport.title2')}
        />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <RDSButton customStyle={{ marginTop: 16, width: '206px' }} color="grey" onClick={copyToClipboard}>
            {translate('page.argateway.ios.notSupport.copy')}
          </RDSButton>
        </div>
      </div>
      <RDSText type="SM/14_semibold" style={{ color: '#BBBBBF' }}>
        Powered by PlicAR
      </RDSText>
    </div>
  );
}

export function ARGatewayiOSFacebook({ translate }: { translate: Function }) {
  function copyToClipboard() {
    const inputc = document.body.appendChild(document.createElement('input'));
    inputc.value = window.location.href;
    inputc.focus();
    inputc.select();
    try {
      document.execCommand('copy'); // deprecated but still works
    } catch {
      inputc.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(inputc.value); // New API But dont support
    }
    const parentNode = inputc.parentNode;
    if (parentNode) parentNode.removeChild(inputc);
    // 주소가 복사되었습니다.\nSafari에서 주소를 붙여넣으세요.
    alert(translate('alert.argateway.ios.copyLink'));
  }

  return (
    <div className={styles['page']}>
      <div className={styles['headerAndContents']}>
        {/* <ARGatewayHeader title_1="아직 지원하지 않는 브라우저입니다." title_2="Safari 로 AR을 체험하세요."/> */}
        <ARGatewayHeader
          title_1={translate('page.argateway.ios.notSupport.title1')}
          title_2={translate('page.argateway.ios.notSupport.title2')}
        />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <RDSButton customStyle={{ marginTop: 16, width: '206px' }} color="grey" onClick={copyToClipboard}>
            {translate('page.argateway.ios.notSupport.copy')}
          </RDSButton>
        </div>
      </div>
      <RDSText type="SM/14_semibold" style={{ color: '#BBBBBF' }}>
        Powered by PlicAR
      </RDSText>
    </div>
  );
}

export function ARGatewayiOSInstagram({ translate }: { translate: Function }) {
  const dotdotdotImage = (
    <svg width="15" height="3" viewBox="0 0 15 3" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="2.29983" cy="1.5" rx="1.39846" ry="1.5" fill="#6073FF" />
      <ellipse cx="7.89358" cy="1.5" rx="1.39846" ry="1.5" fill="#6073FF" />
      <ellipse cx="13.4883" cy="1.5" rx="1.39846" ry="1.5" fill="#6073FF" />
    </svg>
  );

  return (
    <div className={styles['page']}>
      <div className={styles['headerAndContents']}>
        <ARGatewayHeader
          title_1={translate('page.argateway.ios.notSupport.title1')}
          title_2={translate('page.argateway.ios.notSupport.title2')}
        />
        <div className={styles['open-in-safari']}>
          <RDSText type="SM/14_semibold">
            <div className={styles['infomation-step']}>
              1. {dotdotdotImage} {translate('page.argateway.ios.notSupport.click')}
            </div>
          </RDSText>
          <RDSText type="SM/14_semibold">
            <div className={styles['infomation-step']}>
              2.{' '}
              <span className={styles['orange-text-color']} style={{ marginRight: -4 }}>
                {translate('page.argateway.ios.notSupport.openInSafari')}
              </span>
              {translate('page.argateway.ios.notSupport.click')}
            </div>
          </RDSText>
        </div>
      </div>
      <RDSText type="SM/14_semibold" style={{ color: '#BBBBBF' }}>
        Powered by PlicAR
      </RDSText>
    </div>
  );
}

export function ARGatewayiOSKakaotalk({ translate }: { translate: Function }) {
  const shareBtnImage = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 7L8.41 8.41L11 5.83L11 16L13 16L13 5.83L15.58 8.41L17 7L12 2L7 7ZM5 20L5 12L3 12L3 20C3 21.1 3.9 22 5 22L19 22C20.1 22 21 21.1 21 20L21 12L19 12L19 20L5 20Z"
        fill="#6073FF"
      />
    </svg>
  );

  return (
    <div className={styles['page']}>
      <div className={styles['headerAndContents']}>
        <ARGatewayHeader
          title_1={translate('page.argateway.ios.notSupport.title1')}
          title_2={translate('page.argateway.ios.notSupport.title2')}
        />
        <div className={styles['open-in-safari']}>
          <RDSText type="SM/14_semibold">
            <div className={styles['infomation-step']}>
              1. {shareBtnImage} {translate('page.argateway.ios.notSupport.click')}
            </div>
          </RDSText>
          <RDSText type="SM/14_semibold">
            <div className={styles['infomation-step']}>
              2.{' '}
              <span className={styles['orange-text-color']} style={{ marginRight: -4 }}>
                {translate('page.argateway.ios.notSupport.openInSafari')}
              </span>
              {translate('page.argateway.ios.notSupport.click')}
            </div>
          </RDSText>
        </div>
      </div>
      <RDSText type="SM/14_semibold" style={{ color: '#BBBBBF' }}>
        Powered by PlicAR
      </RDSText>
    </div>
  );
}

export function ARGatewayiOSNaverBlog({ translate }: { translate: Function }) {
  // iOS 네이버 블로그
  return <ARGatewayiOSFacebook translate={translate} />;
}

export function ARGatewayiOSNaverWhale({ translate }: { translate: Function }) {
  //  네이버 웨일
  return <ARGatewayiOSFacebook translate={translate} />;
}

export function ARGatewayiOSLine({ translate }: { translate: Function }) {
  //  라인
  return <ARGatewayiOSFacebook translate={translate} />;
}

export function ARGatewayiOSUnsupport({ translate }: { translate: Function }) {
  // 기타 미지원
  return <ARGatewayiOSFacebook translate={translate} />;
}
