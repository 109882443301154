import React, { useState, useEffect } from 'react';
import { RDSText } from '@reconlabs/reconlabs-fe-components';
import { useTranslation } from 'react-i18next';
import { ViewerDataType } from '../../lib/types';
import Cube from '../Cube';
import amplitude from '../../lib/amplitude';
import amplitudeEvents from '../../lib/amplitudeEvents';

export default function ARGatewayAndroid({ viewerData }: { viewerData: ViewerDataType }) {
  const [glbSrc, setGlbSrc] = useState<string>('');
  const [isGlbSrcReady, seIstGlbSrcReady] = useState(false);
  const [isAtagReady, setIsAtagReady] = useState(false);
  const [fallbackInvoked, setFallbackInvoked] = useState(false);

  const { t } = useTranslation();
  const translate = t;

  const getFallbackUrl = () => {
    const params = new URL(window.location.href).searchParams;

    if (!params.has('userid')) {
      params.set('userid', amplitude.userId);
    }

    return window.location.origin + window.location.pathname + '/fallback?' + params.toString();
  };

  // 1. GLB 파일 URL을 controller로부터 signedURL 형태로 획득
  useEffect(() => {
    try {
      if (viewerData.modelGlbURL !== null) {
        setGlbSrc(viewerData.modelGlbURL);
        seIstGlbSrcReady(true);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  // 2. GLB파일이 준비되면, GLB파일의 링크가 들어있는 <a /> 태그 생성
  useEffect(() => {
    if (isGlbSrcReady) {
      const androidAnchor = document.createElement('a');
      androidAnchor.setAttribute('id', 'Plicar-Android-AR');

      if (fallbackInvoked) {
        return;
      }

      const noArViewerSigil = '#model-viewer-no-ar-fallback';
      const location = window.location.toString(); // https://plm.aseeo.io/argateway/aseeo-0001-0001/1
      const locationUrl = new URL(location); // https://plm.aseeo.io/argateway/aseeo-0001-0001/1/1#model-viewer-no-ar-fallback
      const modelUrl = new URL(glbSrc, location); // https://(생략).cloudfront.net/1/1/1/postprocessing(생략)
      const scheme = modelUrl.protocol.replace(':', ''); // https

      locationUrl.hash = noArViewerSigil;
      let intentParams;
      intentParams = `?file=${encodeURIComponent(modelUrl.toString())}&mode=ar_preferred&link=${encodeURIComponent(
        viewerData.salesURL ?? '',
      )}&title=${viewerData.modelName}`;
      // ARViewerParams = {resizable: boolean} 이렇게 하나만 있던가 {resizable: booelan, enableVerticalPlacement: booelan} 이렇게 두개, or '' or undefined
      if (viewerData.ARViewerParams?.resizable === true || viewerData.ARViewerParams?.resizable === false) {
        intentParams += `&resizable=${viewerData.ARViewerParams.resizable}`;
      }
      if (
        viewerData.ARViewerParams?.enableVerticalPlacement === true ||
        viewerData.ARViewerParams?.enableVerticalPlacement === false
      ) {
        intentParams += `&enable_vertical_placement=${viewerData.ARViewerParams.enableVerticalPlacement}`;
      }

      const intent = `intent://arvr.google.com/scene-viewer/1.0${intentParams}#Intent;scheme=${scheme};package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=${getFallbackUrl()};end;`;

      const undoHashChange = () => {
        if (window.location.hash === noArViewerSigil && !fallbackInvoked) {
          setFallbackInvoked(true);
          window.history.back();
        }
      };

      window.addEventListener('hashchange', undoHashChange, {
        once: true,
      });
      androidAnchor.setAttribute('href', intent);
      document.body.appendChild(androidAnchor);

      setIsAtagReady(true);
    }
  }, [isGlbSrcReady]);

  const sendEventAndStartAR = (androidAR: HTMLAnchorElement) => {
    amplitude.sendAsyncEvent(amplitudeEvents.ar.zero_argateway_android_ar_view, amplitude.modelView)
    androidAR.click();
  };

  // 3. GLB파일과 <a /> 태그가 생성되어있으면, <a />태그를 선택하여 AR 뷰어 실행
  useEffect(() => {
    if (isGlbSrcReady && isAtagReady) {
      const androidAR: HTMLAnchorElement | null = document.querySelector('#Plicar-Android-AR');
      if (androidAR) {
        sendEventAndStartAR(androidAR);
      }
    }
  }, [isGlbSrcReady, isAtagReady]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '100vh',
        paddingTop: 40,
        gap: 80,
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          flexGrow: 1,
          gap: 40,
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Cube />
          <div style={{ height: 250, width: 0 }} />
        </div>
        <div style={{ textAlign: 'left', padding: '0 36px' }}>
          <RDSText type="MD/16_extrabold">{translate('page.argateway.mobile.title')}</RDSText>
          <RDSText type="SM/14_semibold" style={{ color: '#646466' }}>
            {translate('page.argateway.mobile.iphone')}
          </RDSText>
          <RDSText type="SM/14_semibold" style={{ color: '#646466' }}>
            {translate('page.argateway.mobile.android')}
          </RDSText>
        </div>
      </div>
      <RDSText type="SM/14_semibold" style={{ color: '#BBBBBF', paddingBottom: 16 }}>
        Powered by PlicAR
      </RDSText>
    </div>
  );
}
