/** @jsxImportSource @emotion/react */

import React, { useState, useEffect } from 'react';
import { RDSText } from '@reconlabs/reconlabs-fe-components';
import { useTranslation } from 'react-i18next';
import { ViewerDataType } from '../../lib/types';
import amplitude from '../../lib/amplitude';
import amplitudeEvents from '../../lib/amplitudeEvents';
import LoadingSpinner from './LoadingSpinner';

// Code for checking AR use period
let ARExperienced = false;
let ARStartedTime: Date;

export default function ARGatewayAndroidFallback({ productMetadata }: { productMetadata: ViewerDataType }) {
  const [glbSrc, setGlbSrc] = useState<string>('');
  const [isGlbSrcReady, seIstGlbSrcReady] = useState(false);
  const [fallbackInvoked, setFallbackInvoked] = useState(false);
  const [arDone, setArDone] = useState<boolean>(false);

  const { t } = useTranslation();
  const translate = t;

  const eventAfterARViwerClosed = () => {
    async function sendLogDataAndCloseTab() {
      if (ARExperienced) {
        if (ARStartedTime != undefined) {
          const AREndedTime = new Date();

          // @noel Amplitude: 이용 시간 Amplitude로 전송
          const ARUseTime = AREndedTime.getTime() - ARStartedTime.getTime(); // millisec
          await amplitude.sendAsyncEvent(amplitudeEvents.ar.zero_argateway_android_ar_experienced_fallback, {
            ar_period: ARUseTime,
            model_view: amplitude.modelView,
          });

          // AR 경험 후에 정상적으로 돌아왔을 때에만 해당 state를 업데이트 합니다. 이는 closeWindow가 불가능한 경우, 안내 화면을 렌더링 합니다.
          setArDone(true);

          setTimeout(function closeWindow() {
            window.close();
            setTimeout(closeWindow, 100);
          }, 100);
        }
      }
    }

    window.addEventListener('focus', sendLogDataAndCloseTab, { once: true });
  };

  // 1. GLB 파일 URL을 controller로부터 signedURL 형태로 획득
  useEffect(() => {
    try {
      if (productMetadata.modelGlbURL !== null) {
        setGlbSrc(productMetadata.modelGlbURL);
        seIstGlbSrcReady(true);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  // 2. GLB파일이 준비되면, GLB파일의 링크가 들어있는 <a /> 태그 생성
  useEffect(() => {
    if (isGlbSrcReady) {
      const anchorCheck = document.getElementById('Plicar-Android-AR');
      if (anchorCheck) return;

      const androidAnchor = document.createElement('a');
      androidAnchor.setAttribute('id', 'Plicar-Android-AR');

      if (fallbackInvoked) {
        return;
      }
      const noArViewerSigil = '#model-viewer-no-ar-fallback';
      const location = window.location.toString(); // https://plm.aseeo.io/argateway/aseeo-0001-0001/1
      const locationUrl = new URL(location); // https://plm.aseeo.io/argateway/aseeo-0001-0001/1/1#model-viewer-no-ar-fallback
      const modelUrl = new URL(glbSrc, location); // https://(생략).cloudfront.net/1/1/1/postprocessing(생략)
      const scheme = modelUrl.protocol.replace(':', ''); // https

      locationUrl.hash = noArViewerSigil;
      let intentParams;
      intentParams = `?file=${encodeURIComponent(modelUrl.toString())}&mode=ar_preferred&link=${encodeURIComponent(
        productMetadata.salesURL ?? '',
      )}&title=${productMetadata.modelName}`;
      // ARViewerParams = {resizable: boolean} 이렇게 하나만 있던가 {resizable: booelan, enableVerticalPlacement: booelan} 이렇게 두개, or '' or undefined
      if (productMetadata.ARViewerParams?.resizable === true || productMetadata.ARViewerParams?.resizable === false) {
        intentParams += `&resizable=${productMetadata.ARViewerParams.resizable}`;
      }
      if (
        productMetadata.ARViewerParams?.enableVerticalPlacement === true ||
        productMetadata.ARViewerParams?.enableVerticalPlacement === false
      ) {
        intentParams += `&enable_vertical_placement=${productMetadata.ARViewerParams.enableVerticalPlacement}`;
      }
      const intent = `intent://arvr.google.com/scene-viewer/1.0${intentParams}#Intent;scheme=${scheme};package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=${'https://plicar.io'};end;`;
      const undoHashChange = () => {
        if (window.location.hash === noArViewerSigil && !fallbackInvoked) {
          setFallbackInvoked(true);
          window.history.back();
        }
      };
      window.addEventListener('hashchange', undoHashChange, {
        once: true,
      });
      androidAnchor.setAttribute('href', intent);
      androidAnchor.innerHTML = translate('page.argateway.fallback');
      const btn = document.getElementById('argateway-andriod-fallback-button');
      btn?.appendChild(androidAnchor);
    }
  }, [isGlbSrcReady]);

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: window.innerHeight + 'px',
        ['@media(min-width: 420px) and (min-height: 640px)']: {
          justifyContent: 'center',
        },
      }}
    >
      {arDone ? (
        <>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <LoadingSpinner />
            <div style={{ height: 250, width: 0 }} />
          </div>
          <div style={{ textAlign: 'left', padding: '0 36px' }}>
            <RDSText type="MD/16_extrabold">{translate('page.argateway.arExperienced')}</RDSText>
          </div>
        </>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            maxWidth: 420,
          }}
        >
          <img src="/images/fallback-ar-guide.png" style={{ width: '100%', marginBottom: 25 }} />
          <div style={{ padding: '0 21px', marginBottom: 34 }}>
            <RDSText type="MD/18_extrabold" style={{ color: '#646466' }}>
              {t('page.argateway.fallbackDesc.title')}
            </RDSText>
            <RDSText type="SM/14_semibold" style={{ color: '#646466' }}>
              1. {t('page.argateway.fallbackDesc.first')}
            </RDSText>
            <RDSText type="SM/14_semibold" style={{ color: '#646466' }}>
              2. {t('page.argateway.fallbackDesc.second')}
            </RDSText>
          </div>
          <div
            id="argateway-andriod-fallback-button"
            style={{
              padding: '10px 16px',
              width: 200,
              textAlign: 'center',
              fontSize: 16,
              lineHeight: '30px',
              fontWeight: 700,
              color: 'white',
              borderRadius: '10px',
              backgroundColor: '#6073FF',
            }}
            onClick={() => {
              ARExperienced = true;
              ARStartedTime = new Date();
              eventAfterARViwerClosed();
              amplitude.sendEvent(amplitudeEvents.ar.zero_argateway_android_ar_view_fallback, amplitude.modelView);
            }}
          ></div>
        </div>
      )}

      <div>
        <RDSText type="SM/14_semibold" style={{ color: '#BBBBBF', padding: 16 }}>
          Powered by PlicAR
        </RDSText>
      </div>
    </div>
  );
}
